/* userList.css */

:root {
    --primary-color: #0057A6;
    --secondary-color: #ef5ba1;
    --accent-color: #f7941d;
    --success-color: #00a651;
    --light-gray: #f5f5f5;
    --medium-gray: #e0e0e0;
    --dark-gray: #555555;
    --white: #ffffff;
    --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
    --border-radius: 8px;
  }
  
  .user-list-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .user-title {
    color: var(--primary-color);
    margin-bottom: 24px;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
  }
  
  .user-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .search-container {
    flex: 1;
    min-width: 200px;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--medium-gray);
    border-radius: var(--border-radius);
    font-size: 1rem;
    transition: var(--transition);
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0, 87, 166, 0.2);
  }
  
  .add-button {
    background-color: var(--success-color);
    color: var(--white);
    border: none;
    padding: 12px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .add-button:hover {
    background-color: #008c44;
    transform: translateY(-2px);
  }
  
  .button-icon {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .form-container {
    background-color: var(--light-gray);
    padding: 20px;
    border-radius: var(--border-radius);
    margin-bottom: 30px;
    box-shadow: var(--box-shadow);
  }
  
  .user-list-wrapper {
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
  }
  
  .user-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid var(--accent-color);
    transition: var(--transition);
  }
  
  .user-item:hover {
    background-color: var(--light-gray);
  }
  
  .user-item:last-child {
    border-bottom: none;
  }
  
  .user-info {
    flex-grow: 1;
  }
  
  .user-name {
    margin: 0 0 4px 0;
    font-size: 1.1rem;
    color: var(--dark-gray);
  }
  
  .user-email {
    margin: 0;
    color: var(--dark-gray);
    font-size: 0.9rem;
  }
  
  .user-action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .edit-button {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
    font-weight: 500;
  }
  
  .edit-button:hover {
    background-color: #004285;
    transform: translateY(-2px);
  }
  
  .delete-button {
    background-color: var(--secondary-color);
    color: var(--white);
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
    font-weight: 500;
  }
  
  .delete-button:hover {
    background-color: #d14b8a;
    transform: translateY(-2px);
  }
  
  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    color: var(--dark-gray);
  }
  
  .spinner {
    border: 4px solid var(--medium-gray);
    border-top: 4px solid var(--primary-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .no-results {
    text-align: center;
    padding: 30px;
    color: var(--dark-gray);
    background-color: var(--light-gray);
    border-radius: var(--border-radius);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .user-item {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .user-info {
      margin-bottom: 12px;
      width: 100%;
    }
    
    .user-action-buttons {
      width: 100%;
    }
    
    .edit-button, .delete-button {
      flex: 1;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .user-controls {
      flex-direction: column;
      align-items: stretch;
    }
    
    .add-button {
      width: 100%;
      justify-content: center;
    }
    
    .user-title {
      font-size: 1.5rem;
    }
  }