
/* Estilos generales */
.dashboard-container {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  color: #333;
}

/* Estilos para las tarjetas de estadísticas */
.bg-white {
  background-color: white;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.border-l-4 {
  border-left-width: 4px;
  border-left-style: solid;
}

.border-blue-500 {
  border-color: #3b82f6;
}

/* Estilos de texto */
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.text-gray-800 {
  color: #1f2937;
}

.text-gray-700 {
  color: #374151;
}

.text-gray-600 {
  color: #4b5563;
}

.text-gray-500 {
  color: #6b7280;
}

.text-white {
  color: white;
}

/* Layout y espaciado */
.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

/* Flexbox utilidades */
.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.flex-1 {
  flex: 1 1 0%;
}

/* Formularios */
.block {
  display: block;
}

.w-full {
  width: 100%;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-gray-300 {
  border-color: #d1d5db;
}

.border-gray-200 {
  border-color: #e5e7eb;
}

.border-b {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

select, input, button {
  outline: none;
}

select:focus, input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25);
}

/* Botones */
button {
  cursor: pointer;
  transition: all 0.2s;
}

.bg-gray-50 {
  background-color: #f9fafb;
}

.bg-gray-100 {
  background-color: #f3f4f6;
}

.bg-gray-200 {
  background-color: #e5e7eb;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.hover\:bg-gray-300:hover {
  background-color: #d1d5db;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Tabla */
.min-w-full {
  min-width: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.overflow-x-auto {
  overflow-x: auto;
}

.hover\:bg-gray-50:hover {
  background-color: #f9fafb;
}

/* Altura del gráfico */
.h-96 {
  height: 24rem;
}

/* Posicionamiento para notificaciones */
.fixed {
  position: fixed;
}

.bottom-6 {
  bottom: 1.5rem;
}

.right-6 {
  right: 1.5rem;
}

.max-w-sm {
  max-width: 24rem;
}

/* Estilos de notificación */
.notification {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(1rem);
  animation: slideIn 0.3s forwards;
}

.notification.Pendiente {
  border-left: 4px solid #ef5ba1;
}

.notification.Proceso {
  border-left: 4px solid #ffea00;
}

.notification.Listo {
  border-left: 4px solid #00a651;
}

.notification.error {
  border-left: 4px solid #ef4444;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries para responsividad */
@media (max-width: 1024px) {
  .grid-cols-12 {
    grid-template-columns: 1fr;
  }
  
  .col-span-3, .col-span-9 {
    grid-column: span 1 / span 1;
  }
  
  .grid-cols-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .grid-cols-4 {
    grid-template-columns: 1fr;
  }
  
  .h-96 {
    height: 18rem;
  }
}