/* circunscripcionForm.css - Versión mejorada */

/* Utilizamos las mismas variables que definimos en circunscripcionList.css */
:root {
    --primary-color: #3498db;
    --primary-hover: #2980b9;
    --danger-color: #e74c3c;
    --danger-hover: #c0392b;
    --success-color: #2ecc71;
    --success-hover: #27ae60;
    --bg-light: #f8f9fa;
    --bg-card: #ffffff;
    --text-dark: #333333;
    --text-muted: #6c757d;
    --border-color: #dee2e6;
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --radius: 8px;
    --transition: all 0.3s ease;
  }
  
  /* Estilos del overlay modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  /* Contenido del modal */
  .modal-content {
    background-color: var(--bg-card);
    border-radius: var(--radius);
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeInUp 0.3s;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Botón para cerrar el modal */
  .modal-close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    cursor: pointer;
    color: var(--text-muted);
    padding: 0.25rem 0.5rem;
    border-radius: 50%;
    transition: var(--transition);
  }
  
  .modal-close:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--danger-color);
  }
  
  /* Formulario dentro del modal */
  .modal-content form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  
  .modal-content h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: var(--primary-color);
    font-size: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid var(--primary-color);
  }
  
  /* Grupos de formulario */
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: 500;
    color: var(--text-dark);
    font-size: 0.95rem;
  }
  
  /* Campo de entrada de texto */
  .modal-content input[type="text"] {
    padding: 0.75rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    font-size: 1rem;
    transition: var(--transition);
    width: 100%;
  }
  
  .modal-content input[type="text"]:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  }
  
  /* Área de texto */
  .modal-content textarea {
    padding: 0.75rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    font-size: 1rem;
    transition: var(--transition);
    width: 100%;
    min-height: 120px;
    resize: vertical;
    font-family: inherit;
  }
  
  .modal-content textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  }
  
  /* Botones del formulario */
  .modal-content button[type="submit"] {
    padding: 0.75rem 1.25rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    font-weight: 600;
    transition: var(--transition);
    margin-top: 0.5rem;
  }
  
  .modal-content button[type="submit"]:hover {
    background-color: var(--primary-hover);
  }
  
  /* Indicador de campo requerido */
  .required-field::after {
    content: "*";
    color: var(--danger-color);
    margin-left: 0.25rem;
  }
  
  /* Acciones del formulario */
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .btn-cancel-form {
    padding: 0.75rem 1.25rem;
    background-color: var(--bg-light);
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    cursor: pointer;
    font-weight: 500;
    transition: var(--transition);
  }
  
  .btn-cancel-form:hover {
    background-color: #e9ecef;
  }
  
  /* Mensaje de error en el formulario */
  .form-error {
    color: var(--danger-color);
    font-size: 0.85rem;
    margin-top: 0.25rem;
  }
  
  /* Iconos para los campos */
  .input-icon-wrapper {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-muted);
  }
  
  .input-with-icon {
    padding-left: 2.5rem;
  }
  
  /* Estilos para focus dentro del formulario */
  .modal-content input:focus,
  .modal-content textarea:focus,
  .modal-content select:focus,
  .modal-content button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 576px) {
    .modal-content {
      padding: 1.5rem;
    }
    
    .form-actions {
      flex-direction: column;
      gap: 0.5rem;
    }
    
    .form-actions button {
      width: 100%;
    }
  }
  
  /* Animación para el estado de carga del formulario */
  .loading-button {
    position: relative;
    padding-left: 2.5rem !important;
  }
  
  .loading-button::before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { transform: translateY(-50%) rotate(360deg); }
  }
  
  /* Tooltip para ayudar al usuario */
  .tooltip {
    position: relative;
    display: inline-block;
    margin-left: 0.5rem;
    cursor: help;
  }
  
  .tooltip-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--text-muted);
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.85rem;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }