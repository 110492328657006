/* sectorForm.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    animation: slideIn 0.3s ease;
  }
  
  @keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
  }
  
  .modal-header h3 {
    margin: 0;
    color: #333;
    font-size: 1.25rem;
  }
  
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #777;
    transition: color 0.2s;
  }
  
  .modal-close:hover {
    color: #333;
  }
  
  form {
    padding: 20px;
  }
  
  .form-row {
    margin-bottom: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 6px;
    font-weight: 500;
    color: #555;
    font-size: 0.9rem;
  }
  
  input[type="text"],
  input[type="number"],
  input[type="date"],
  select {
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="date"]:focus,
  select:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
    outline: none;
  }
  
  .coordinates-section {
    background-color: #f9f9f9;
    border-radius: 6px;
    padding: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .coordinates-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .coordinates-header h4 {
    margin: 0;
    font-size: 1rem;
    color: #333;
  }
  
  .coordinate-card {
    background-color: white;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 16px;
  }
  
  .coordinate-info {
    margin-bottom: 16px;
  }
  
  .coordinate-info p {
    font-weight: 500;
    color: #666;
    margin-bottom: 12px;
  }
  
  .coordinate-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
  
  @media (max-width: 576px) {
    .coordinate-inputs {
      grid-template-columns: 1fr;
    }
  }
  
  .no-coordinates {
    text-align: center;
    padding: 20px 0;
    color: #777;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
  
  .btn-paginate {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-paginate:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
  
  .btn-paginate:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .btn-submit {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-submit:hover {
    background-color: #3a80d2;
  }
  
  .btn-cancel {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-cancel:hover {
    background-color: #e0e0e0;
  }
  
  .btn-add-coordinate {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-add-coordinate:hover {
    background-color: #3a80d2;
  }
  
  /* sectorList.css */
  .sectors-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sectors-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .sectors-header h2 {
    margin: 0;
    color: #333;
    font-size: 1.75rem;
  }
  
  .btn-primary {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-primary:hover {
    background-color: #3a80d2;
  }
  
  .filter-section {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
  }
  
  .search-box {
    position: relative;
    margin-bottom: 16px;
  }
  
  .search-box input {
    width: 100%;
    padding: 12px 16px 12px 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }
  
  .filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-end;
  }
  
  .filter-group {
    flex: 1;
    min-width: 160px;
  }
  
  @media (max-width: 768px) {
    .filter-group {
      flex-basis: 100%;
    }
  }
  
  .filter-group label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    color: #555;
    font-size: 0.9rem;
  }
  
  .btn-clear-filters {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
    height: 40px;
    margin-top: 12px;
  }
  
  .btn-clear-filters:hover {
    background-color: #e0e0e0;
  }
  
  .view-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .view-toggles {
    display: flex;
    gap: 8px;
  }
  
  .view-toggle {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .view-toggle.active {
    background-color: #4a90e2;
    color: white;
    border-color: #4a90e2;
  }
  
  .results-info {
    color: #777;
    font-size: 0.9rem;
  }
  
  /* Sector Grid View */
  .sectors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .sector-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .sector-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .sector-card-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sector-card-header h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .status-pending {
    background-color: #fff3cd;
    color: #856404;
  }
  
  .status-in-progress {
    background-color: #d1ecf1;
    color: #0c5460;
  }
  
  .status-completed {
    background-color: #d4edda;
    color: #155724;
  }
  
  .sector-card-body {
    padding: 16px;
  }
  
  .sector-card-body p {
    margin: 8px 0;
    color: #555;
  }
  
  .sector-card-footer {
    padding: 12px 16px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .btn-edit, .btn-delete {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-edit {
    background-color: #e6f2ff;
    color: #0366d6;
    border: 1px solid #cce5ff;
  }
  
  .btn-edit:hover {
    background-color: #d6ebff;
  }
  
  .btn-delete {
    background-color: #fff5f5;
    color: #e53e3e;
    border: 1px solid #fed7d7;
  }
  
  .btn-delete:hover {
    background-color: #fee6e6;
  }
  
  /* Sector Table View */
  .sectors-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  
  .sectors-table th {
    background-color: #f5f5f5;
    text-align: left;
    padding: 12px 16px;
    font-weight: 500;
    color: #333;
    border-bottom: 2px solid #ddd;
  }
  
  .sectors-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    color: #444;
  }
  
  .sectors-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .action-buttons {
    white-space: nowrap;
    display: flex;
    gap: 8px;
  }
  
  /* Loading and Empty States */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    color: #777;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #4a90e2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .no-results {
    text-align: center;
    padding: 40px 0;
    color: #777;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  /* Confirmation Dialog */
  .confirm-dialog {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    animation: fadeIn 0.2s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .confirm-dialog h3 {
    margin-top: 0;
    color: #333;
  }
  
  .confirm-dialog p {
    margin-bottom: 20px;
    color: #555;
  }
  
  .confirm-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .btn-confirm {
    background-color: #e53e3e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .btn-confirm:hover {
    background-color: #c53030;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .sectors-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .sectors-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    
    .view-controls {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
    
    .results-info {
      margin-top: 8px;
    }
    
    .sectors-table {
      display: block;
      overflow-x: auto;
    }
  }
  
  @media (max-width: 576px) {
    .sectors-grid {
      grid-template-columns: 1fr;
    }
    
    .sector-card-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
    
    .sector-card-footer {
      justify-content: space-between;
    }
    
    .form-actions {
      flex-direction: column;
    }
    
    .btn-submit, .btn-cancel {
      width: 100%;
    }
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
  }