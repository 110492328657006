/* roleForm.css */

:root {
    --primary-color: #0057A6;
    --secondary-color: #ef5ba1;
    --accent-color: #f7941d;
    --success-color: #00a651;
    --light-gray: #f5f5f5;
    --medium-gray: #e0e0e0;
    --dark-gray: #555555;
    --error-color: #dc3545;
    --white: #ffffff;
    --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
    --border-radius: 8px;
  }
  
  .role-form-wrapper {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 24px;
    margin-bottom: 24px;
  }
  
  .form-title {
    color: var(--primary-color);
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }
  
  .role-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .form-label {
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--dark-gray);
    font-size: 0.95rem;
  }
  
  .form-input,
  .form-textarea {
    padding: 12px 16px;
    border: 1px solid var(--medium-gray);
    border-radius: var(--border-radius);
    font-size: 1rem;
    transition: var(--transition);
    width: 100%;
    font-family: inherit;
  }
  
  .form-textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0, 87, 166, 0.2);
  }
  
  .input-error {
    border-color: var(--error-color);
    background-color: rgba(220, 53, 69, 0.05);
  }
  
  .error-message {
    color: var(--error-color);
    font-size: 0.85rem;
    margin-top: 5px;
    margin-bottom: 0;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 10px;
  }
  
  .submit-button {
    background-color: var(--success-color);
    color: var(--white);
    border: none;
    padding: 12px 24px;
    border-radius: var(--border-radius);
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .submit-button:hover {
    background-color: #008c44;
    transform: translateY(-2px);
  }
  
  .cancel-button {
    background-color: var(--light-gray);
    color: var(--dark-gray);
    border: 1px solid var(--medium-gray);
    padding: 12px 24px;
    border-radius: var(--border-radius);
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .cancel-button:hover {
    background-color: #e6e6e6;
  }
  
  /* Responsive styles */
  @media (max-width: 480px) {
    .form-actions {
      flex-direction: column-reverse;
    }
    
    .submit-button, .cancel-button {
      width: 100%;
      text-align: center;
    }
    
    .role-form-wrapper {
      padding: 16px;
    }
  }