/* Estilos generales para el contenedor del historial */
.history-dashboard-container {
    background-color: #fdfdfd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    height: 250px;
    overflow-y: auto;
}

/* Estilo del título */
.history-dashboard-title {
    font-size: 1.6em;
    color: #67C0EC;
    text-align: center;
    margin-bottom: 20px;
}

/* Estilo de la tabla */
.history-table {
    width: 100%;
    border-collapse: collapse;
}

.history-table thead {
    background-color: #0073e6;
    color: #fff;
}

.history-table th,
.history-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.history-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.history-table tbody tr:hover {
    background-color: #e0f7fa;
}

.history-table th {
    font-weight: bold;
    font-size: 1.1em;
}
