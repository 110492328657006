.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.error-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 48px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;
}

.error-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 130px;
  background: linear-gradient(135deg, #f06, #4c83ff);
  border-radius: 50%;
  margin-bottom: 24px;
}

.error-code {
  color: white;
  font-size: 48px;
  font-weight: bold;
}

.error-title {
  color: #333;
  font-size: 28px;
  margin-bottom: 16px;
}

.error-message {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 32px;
}

.error-button {
  display: inline-block;
  background: linear-gradient(135deg, #4c83ff, #2962ff);
  color: white;
  padding: 12px 28px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(76, 131, 255, 0.3);
}

.error-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(76, 131, 255, 0.4);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design */
@media (max-width: 600px) {
  .error-card {
    padding: 32px 24px;
  }
  
  .error-icon {
    height: 100px;
    width: 100px;
  }
  
  .error-code {
    font-size: 36px;
  }
  
  .error-title {
    font-size: 22px;
  }
}