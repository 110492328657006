:root {
    --primary-color: #3498db;
    --primary-hover: #2980b9;
    --primary-bg: #0059A8;
    --accent-color: #F36C21;
    --accent-hover: #e45e1a;
    --highlight-color: #67C0EC;
    --danger-color: #EF5BA1;
    --danger-hover: #d60909;
    --text-light: #ffffff;
    --sidebar-width: 250px;
    --sidebar-collapsed-width: 60px;
    --transition-speed: 0.3s;
    --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    --header-height: 60px;
  }
  
  .app-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  
  .sidebar {
    background-color: var(--primary-bg);
    height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: var(--sidebar-width);
    transition: all var(--transition-speed) ease;
    box-shadow: var(--box-shadow);
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
  }
  
  .sidebar.collapsed {
    width: var(--sidebar-collapsed-width);
  }
  
  .menu-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--highlight-color);
    display: flex;
    height: var(--header-height);
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: var(--primary-bg);
    transition: color var(--transition-speed);
  }
  
  .menu-button:hover {
    color: var(--text-light);
    background-color: var(--accent-color);
  }
  
  .titulo-sidebar {
    font-size: 1.5em;
    color: var(--highlight-color);
    text-align: center;
    margin: 0;
    padding: 15px 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .sidebar.collapsed .titulo-sidebar {
    display: none;
  }
  
  .lista-sidebar {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
  }
  
  .lista-sidebar li {
    width: 100%;
    margin-bottom: 5px;
  }
  
  .lista-sidebar li a {
    color: var(--text-light);
    text-decoration: none;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    transition: background-color var(--transition-speed);
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .lista-sidebar li a .icon {
    margin-right: 10px;
    font-size: 1.2em;
    min-width: 24px;
    text-align: center;
  }
  
  .sidebar.collapsed .lista-sidebar li a {
    justify-content: center;
    padding: 12px 0;
  }
  
  .sidebar.collapsed .lista-sidebar li a .icon {
    margin-right: 0;
    font-size: 1.5em;
  }
  
  .lista-sidebar li a:hover {
    background-color: var(--accent-color);
  }
  
  .sidebar.collapsed .lista-sidebar li a .text {
    display: none;
  }
  
  .lista-sidebar li button {
    font-size: 1.1em;
    color: var(--text-light);
    background-color: var(--danger-color);
    border: none;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    border-radius: 0;
    transition: background-color var(--transition-speed);
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  
  .lista-sidebar li button .icon {
    margin-right: 10px;
    font-size: 1.2em;
    min-width: 24px;
    text-align: center;
  }
  
  .sidebar.collapsed .lista-sidebar li button {
    justify-content: center;
    padding: 12px 0;
  }
  
  .sidebar.collapsed .lista-sidebar li button .icon {
    margin-right: 0;
    font-size: 1.5em;
  }
  
  .sidebar.collapsed .lista-sidebar li button .text {
    display: none;
  }
  
  .lista-sidebar li button:hover {
    background-color: var(--danger-hover);
  }
  
  .main-content {
    flex-grow: 1;
    transition: margin-left var(--transition-speed) ease;
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
    overflow-y: auto;
    padding: 20px;
  }
  
  .main-content.collapsed {
    width: calc(100% - var(--sidebar-collapsed-width));
    margin-left: var(--sidebar-collapsed-width);
  }
  
  /* Add tooltip for collapsed sidebar */
  .sidebar.collapsed .lista-sidebar li {
    position: relative;
  }
  
  .sidebar.collapsed .lista-sidebar li:hover::after {
    content: attr(data-title);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #333;
    color: white;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 101;
    margin-left: 10px;
  }
  
  /* Mobile responsive styles */
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-100%);
      position: fixed;
      top: 0;
      left: 0;
    }
    
    .sidebar.collapsed {
      width: 0;
      overflow: hidden;
    }
    
    .sidebar:not(.collapsed) {
      transform: translateX(0);
      width: 100%;
      max-width: 300px;
    }
    
    .main-content {
      width: 100%;
      margin-left: 0;
    }
    
    .main-content.collapsed {
      width: 100%;
      margin-left: 0;
    }
    
    .mobile-menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 90;
      display: none;
    }
    
    .sidebar:not(.collapsed) + .mobile-menu-overlay {
      display: block;
    }
  }