/* Mapa.css */

.mapa-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mapa-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.mapa-titulo {
    color: #0057A6;
    margin: 0;
    font-weight: 600;
    font-size: 1.75rem;
    border-left: 5px solid #008f45;
    padding-left: 15px;
}

.mapa-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.search-container {
    position: relative;
    flex-grow: 1;
}

.mapa-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.3s;
    box-shadow: 0 1px 3px rgba(0,0,0,0.08);
}

.mapa-input:focus {
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
    outline: none;
}

.sugerencias-lista {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000;
}

.sugerencia-item {
    padding: 12px 15px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.2s;
}

.sugerencia-item:hover {
    background-color: rgba(52, 152, 219, 0.1);
}

.sugerencia-item:last-child {
    border-bottom: none;
    border-radius: 0 0 6px 6px;
}

.button {
    padding: 12px 15px;
    border: none;
    border-radius: 6px;
    background-color: #3498db;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;
}

.button:hover {
    background-color: #2980b9;
}

.button-secondary {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #e0e0e0;
}

.button-secondary:hover {
    background-color: #f8f9fa;
}

.mapa-content {
    position: relative;
    height: 550px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}

.ol-attribution {
    font-size: 12px;
}

.sector-info-panel {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    z-index: 1000;
    overflow: hidden;
    opacity: 0.95;
}

.sector-info-header {
    padding: 15px 20px;
    background-color: #0057A6;
    color: white;
}

.sector-info-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.sector-info-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
}

.sector-info-body {
    padding: 15px 20px;
}

.sector-info-row {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.sector-info-label {
    font-weight: 600;
    width: 40%;
    color: #333333;
}

.sector-info-value {
    width: 60%;
}

.sector-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.status-pendiente {
    background-color: #e74c3c;
}

.status-proceso {
    background-color: #f39c12;
}

.status-completado {
    background-color: #008f45;
}

.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(52, 152, 219, 0.3);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

.legend {
    position: absolute;
    bottom: 15px;
    left: 15px;
    background: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.15);
    z-index: 900;
}

.legend-title {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 14px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 12px;
}

.legend-color {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 3px;
}

.popup-container {
    width: 250px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.popup-header {
    padding: 10px 15px;
    color: white;
}

.popup-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.popup-body {
    padding: 10px 15px;
}

.popup-body p {
    margin: 5px 0;
    font-size: 14px;
}

.popup-button {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    background-color: #0057A6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.popup-button:hover {
    background-color: #3498db;
}

