/* Estilos generales */
.dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .dashboard-title {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Estilos para las tarjetas de sectores */
  .sector-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .sector-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
    overflow: hidden;
  }
  
  .sector-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .sector-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .status-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .sector-name {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .sector-card-body {
    margin-bottom: 15px;
  }
  
  .sector-status, .scheduled-date {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
  }
  
  .accept-assignment-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  .accept-assignment-btn:hover {
    background-color: #388E3C;
  }
  
  /* Estilos para detalles del sector seleccionado */
  .selected-sector-details {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
  }
  
  /* Botón para volver atrás */
  .back-btn {
    background-color: #607D8B;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .back-btn i {
    margin-right: 8px;
  }
  
  .back-btn:hover {
    background-color: #455A64;
  }
  
  /* Encabezado del sector seleccionado */
  .sector-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .sector-title {
    margin: 0;
    font-size: 22px;
    color: #333;
  }
  
  .edit-status-btn {
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-status-btn:hover {
    background-color: #303f9f;
  }
  
  .sector-history-container {
    margin-top: 30px;
  }
  
  .sector-history-container h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Estilos para información de brigada */
  .brigade-info {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .brigade-members-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
  }
  
  .member-card {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 10px;
  }
  
  .member-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #3f51b5;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .member-name {
    font-size: 14px;
  }
  
  /* Estilos para modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group select,
  .form-group textarea,
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .update-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .close-btn {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  .reject-assignment-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s;
    margin-top: 10px;
    width: 100%;
    display: block;
    text-align: center;
}

.reject-assignment-btn:hover {
    background-color: #d32f2f;
}

/* Si necesitas estilos específicos para cuando está en la vista de detalles */
.sector-actions .reject-assignment-btn {
    margin-top: 0;
    margin-right: 10px;
    width: auto;
    display: inline-block;
}