/* Variables de colores */
:root {
    --color-principal: #0059a8;
    --color-hover: #f36c21;
    --color-titulo: #67c0ec;
    --color-boton-cerrar-sesion: #ef5ba1;
  
    /* Colores adicionales para el formulario */
    --text-color: #333333;
    --text-light: #666666;
    --background-color: #f8f9fa;
    --card-background: #ffffff;
    --border-color: #e0e0e0;
    --error-color: #e74c3c;
    --success-color: #2ecc71;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --input-background: #f5f5f5;
    --input-focus: #e8f0fe;
  }
  
  /* Estilos generales */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-color);
    padding: 20px;
    background-image: linear-gradient(135deg, rgba(0, 89, 168, 0.1) 0%, rgba(103, 192, 236, 0.1) 100%);
  }
  
  .login-card {
    width: 100%;
    max-width: 420px;
    background-color: var(--card-background);
    border-radius: 12px;
    box-shadow: 0 8px 20px var(--shadow-color);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeIn 0.5s ease-out;
    border-top: 4px solid var(--color-principal);
  }
  
  .login-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 25px rgba(0, 89, 168, 0.2);
  }
  
  .login-header {
    padding: 30px 30px 20px;
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    background-color: rgba(103, 192, 236, 0.05);
  }
  
  .login-header h2 {
    margin: 0 0 10px;
    color: var(--color-titulo);
    font-size: 28px;
    font-weight: 600;
  }
  
  .login-header p {
    margin: 0;
    color: var(--text-light);
    font-size: 16px;
  }
  
  .login-form {
    padding: 30px;
  }
  
  .form-group {
    margin-bottom: 24px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-principal);
  }
  
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-icon {
    position: absolute;
    left: 12px;
    color: var(--color-principal);
    font-size: 16px;
  }
  
  .input-container input {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    font-size: 16px;
    color: var(--text-color);
    transition: all 0.3s ease;
  }
  
  .input-container input:focus {
    outline: none;
    border-color: var(--color-principal);
    background-color: var(--input-focus);
    box-shadow: 0 0 0 3px rgba(0, 89, 168, 0.1);
  }
  
  .input-container input.input-error {
    border-color: var(--error-color);
    background-color: rgba(231, 76, 60, 0.05);
  }
  
  .toggle-password {
    position: absolute;
    right: 12px;
    background: none;
    border: none;
    color: var(--text-light);
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
  }
  
  .toggle-password:hover {
    color: var(--color-hover);
  }
  
  .error-text {
    display: block;
    color: var(--error-color);
    font-size: 12px;
    margin-top: 5px;
    animation: fadeIn 0.3s ease;
  }
  
  .error-message {
    background-color: rgba(231, 76, 60, 0.1);
    border-left: 4px solid var(--error-color);
    padding: 12px 20px;
    margin: 20px 30px 0;
    border-radius: 4px;
    animation: fadeIn 0.3s ease;
  }
  
  .error-message p {
    margin: 0;
    color: var(--error-color);
    font-size: 14px;
  }
  
  .login-button {
    width: 100%;
    padding: 14px;
    background-color: var(--color-principal);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .login-button:hover {
    background-color: var(--color-hover);
  }
  
  .login-button:active {
    transform: scale(0.98);
  }
  
  .login-button:disabled {
    background-color: var(--text-light);
    cursor: not-allowed;
  }
  
  .login-button.loading {
    background-color: var(--color-hover);
    cursor: wait;
  }
  
  /* Spinner para estado de carga */
  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 0.8s linear infinite;
  }
  
  /* Animaciones */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Efectos adicionales */
  .input-container input:focus + .input-icon {
    color: var(--color-hover);
  }
  
  .login-card::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, var(--color-principal), var(--color-titulo), var(--color-hover));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .login-card:hover::after {
    opacity: 1;
  }
  
  /* Responsive */
  @media (max-width: 480px) {
    .login-card {
      max-width: 100%;
    }
  
    .login-header {
      padding: 20px 20px 15px;
    }
  
    .login-header h2 {
      font-size: 24px;
    }
  
    .login-form {
      padding: 20px;
    }
  
    .form-group {
      margin-bottom: 20px;
    }
  
    .input-container input {
      padding: 10px 10px 10px 36px;
      font-size: 15px;
    }
  
    .login-button {
      padding: 12px;
    }
  }
  
  