/* brigade-list.css */

:root {
  --primary-color: #3498db;
  --primary-hover: #2980b9;
  --danger-color: #e74c3c;
  --danger-hover: #c0392b;
  --success-color: #2ecc71;
  --success-hover: #27ae60;
  --light-gray: #f5f5f5;
  --border-color: #ddd;
  --text-color: #333;
  --text-secondary: #666;
}

.brigade-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.brigade-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.brigade-title {
  font-size: 1.8rem;
  color: var(--text-color);
  margin: 0;
}

.brigade-actions-top {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Buttons */
.btn-add, .btn-export, .btn-action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  border-radius: 6px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-add {
  background-color: var(--success-color);
  color: white;
}

.btn-add:hover {
  background-color: var(--success-hover);
}

.btn-add.active {
  background-color: var(--danger-color);
}

.btn-add.active:hover {
  background-color: var(--danger-hover);
}

.export-buttons {
  display: flex;
  gap: 0.5rem;
}

.btn-export {
  background-color: var(--primary-color);
  color: white;
}

.btn-export:hover {
  background-color: var(--primary-hover);
}

/* Form */
.brigade-form-wrapper {
  transition: all 0.3s ease;
  animation: slideDown 0.3s forwards;
}

.form-title {
  margin-top: 0;
  color: var(--text-color);
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

/* List section */
.list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  
}


.list-controls {
  display: flex;
  gap: 5rem;
  align-items: center;
 
}

.search-box {
  position: relative;
  flex-grow: 1;
 
}

.search-box i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
}

.search-box input {
  width: 100%;
  padding: 0.6rem 0.6rem 0.6rem 2rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.9rem;
}

.items-per-page {
  padding: 0.6rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: white;
  margin-left: 1rem;
}

/* Brigade items */
.brigade-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.brigade-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.brigade-item:last-child {
  border-bottom: none;
}

.brigade-item:hover {
  background-color: var(--light-gray);
}

.brigade-content {
  flex-grow: 1;
}

.brigade-name {
  font-size: 1.1rem;
  margin: 0 0 0.5rem 0;
  color: var(--text-color);
}

.brigade-description {
  margin: 0 0 0.5rem 0;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.members-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.members-count {
  font-size: 0.85rem;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.brigade-buttons {
  display: flex;
  gap: 0.5rem;
}

.btn-action {
  padding: 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.btn-edit {
  background-color: var(--primary-color);
}

.btn-edit:hover {
  background-color: var(--primary-hover);
}

.btn-pdf {
  background-color: #ff9800;
}

.btn-pdf:hover {
  background-color: #f57c00;
}

.btn-delete {
  background-color: var(--danger-color);
}

.btn-delete:hover {
  background-color: var(--danger-hover);
}

/* Empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: var(--text-secondary);
  text-align: center;
}

.empty-state i {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ccc;
}

.clear-search {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--light-gray);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
}

/* Loading state */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: var(--text-secondary);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: var(--primary-color);
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

/* Error state */
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: var(--danger-color);
  text-align: center;
}

.error-message i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.retry-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--light-gray);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.page-numbers {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.page-btn {
  min-width: 2rem;
  height: 2rem;
  background-color: var(--light-gray);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.page-btn:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.page-btn.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ellipsis {
  margin: 0 0.2rem;
}

.pagination-info {
  text-align: center;
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

/* Animations */
@keyframes slideDown {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .brigade-header, .list-header {
      flex-direction: column;
      align-items: flex-start;
  }
  
  .brigade-item {
      flex-direction: column;
      align-items: flex-start;
  }
  
  .brigade-buttons {
      margin-top: 1rem;
      align-self: flex-end;
  }
  
  .list-controls {
      width: 100%;
      flex-direction: column;
  }
  
  .search-box {
      width: 100%;
  }
  
  .items-per-page {
      align-self: flex-end;
  }
}