/* circunscripcionList.css - Versión mejorada */

/* Variables globales para consistencia */
:root {
    --primary-color: #3498db;
    --primary-hover: #2980b9;
    --danger-color: #e74c3c;
    --danger-hover: #c0392b;
    --success-color: #2ecc71;
    --success-hover: #27ae60;
    --bg-light: #f8f9fa;
    --bg-card: #ffffff;
    --text-dark: #333333;
    --text-muted: #6c757d;
    --border-color: #dee2e6;
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --radius: 8px;
    --transition: all 0.3s ease;
  }
  
  /* Contenedor principal con máximo ancho y centrado */
  .circunscripciones-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--text-dark);
  }
  
  /* Cabecera con diseño flexible */
  .circunscripciones-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .circunscripciones-header h2 {
    margin: 0;
    font-size: 1.8rem;
    color: var(--primary-color);
    position: relative;
  }
  
  .circunscripciones-header h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 60px;
    height: 3px;
    background-color: var(--primary-color);
  }
  
  /* Sección de filtros mejorada */
  .filter-section {
    margin-bottom: 1.5rem;
  }
  
  .search-box {
    position: relative;
    max-width: 400px;
  }
  
  .search-box input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    font-size: 1rem;
    transition: var(--transition);
  }
  
  .search-box input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  }
  
  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-muted);
  }
  
  /* Controles de vista mejorados */
  .view-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .view-toggles {
    display: flex;
    background-color: var(--bg-light);
    border-radius: var(--radius);
    overflow: hidden;
  }
  
  .view-toggle {
    padding: 0.6rem 1rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    transition: var(--transition);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .view-toggle.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  .view-toggle:not(.active):hover {
    background-color: rgba(52, 152, 219, 0.1);
  }
  
  .results-info {
    font-size: 0.9rem;
    color: var(--text-muted);
  }
  
  /* Cuadrícula de circunscripciones mejorada */
  .circunscripciones-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .circunscripcion-card {
    background-color: var(--bg-card);
    border-radius: var(--radius);
    overflow: hidden;
    box-shadow: var(--shadow);
    transition: var(--transition);
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
  }
  
  .circunscripcion-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .circunscripcion-card-header {
    padding: 1.25rem;
    background-color: var(--primary-color);
    color: white;
  }
  
  .circunscripcion-card-header h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .circunscripcion-card-body {
    padding: 1.25rem;
    flex-grow: 1;
  }
  
  .circunscripcion-card-body p {
    margin: 0;
    line-height: 1.6;
    color: var(--text-muted);
  }
  
  .circunscripcion-card-footer {
    padding: 1rem 1.25rem;
    background-color: var(--bg-light);
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
  }
  
  /* Estilos de tabla mejorados */
  .circunscripciones-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 2rem;
    border-radius: var(--radius);
    overflow: hidden;
    box-shadow: var(--shadow);
  }
  
  .circunscripciones-table th {
    background-color: var(--bg-light);
    padding: 1rem;
    text-align: left;
    font-weight: 600;
    border-bottom: 2px solid var(--border-color);
  }
  
  .circunscripciones-table td {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    vertical-align: middle;
  }
  
  .circunscripciones-table tr:last-child td {
    border-bottom: none;
  }
  
  .circunscripciones-table tr:hover {
    background-color: rgba(52, 152, 219, 0.05);
  }
  
  .action-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  /* Paginación mejorada */
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
  }
  
  .pagination-button {
    padding: 0.6rem 1rem;
    background-color: var(--bg-light);
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
    font-size: 0.9rem;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 0.9rem;
    color: var(--text-muted);
  }
  
  /* Estado de carga mejorado */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    text-align: center;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(52, 152, 219, 0.2);
    border-radius: 50%;
    border-top-color: var(--primary-color);
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 1rem;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  /* Estado sin resultados mejorado */
  .no-results {
    text-align: center;
    padding: 3rem;
    background-color: var(--bg-light);
    border-radius: var(--radius);
    color: var(--text-muted);
  }
  
  /* Diálogo de confirmación mejorado */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirm-dialog {
    background-color: var(--bg-card);
    border-radius: var(--radius);
    padding: 1.5rem;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    animation: fadeInUp 0.3s;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .confirm-dialog h3 {
    margin-top: 0;
    color: var(--danger-color);
  }
  
  .confirm-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  /* Botones estilizados */
  .btn-primary {
    padding: 0.75rem 1.25rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: var(--transition);
  }
  
  .btn-primary:hover {
    background-color: var(--primary-hover);
  }
  
  .btn-edit {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    font-size: 0.9rem;
    transition: var(--transition);
  }
  
  .btn-edit:hover {
    background-color: var(--primary-hover);
  }
  
  .btn-delete {
    padding: 0.5rem 1rem;
    background-color: var(--danger-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    font-size: 0.9rem;
    transition: var(--transition);
  }
  
  .btn-delete:hover {
    background-color: var(--danger-hover);
  }
  
  .btn-cancel {
    padding: 0.6rem 1.25rem;
    background-color: var(--bg-light);
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    cursor: pointer;
    font-weight: 500;
    transition: var(--transition);
  }
  
  .btn-cancel:hover {
    background-color: #e9ecef;
  }
  
  .btn-confirm {
    padding: 0.6rem 1.25rem;
    background-color: var(--danger-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    font-weight: 500;
    transition: var(--transition);
  }
  
  .btn-confirm:hover {
    background-color: var(--danger-hover);
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 768px) {
    .circunscripciones-container {
      padding: 1rem;
    }
    
    .circunscripciones-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .view-controls {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .circunscripciones-grid {
      grid-template-columns: 1fr;
    }
    
    .circunscripciones-table {
      display: block;
      overflow-x: auto;
    }
    
    .pagination-container {
      flex-wrap: wrap;
    }
  }
  
  /* Transiciones suaves para los elementos interactivos */
  .circunscripcion-card,
  .btn-primary,
  .btn-edit,
  .btn-delete,
  .btn-cancel,
  .btn-confirm,
  .pagination-button,
  .view-toggle,
  input {
    transition: var(--transition);
  }