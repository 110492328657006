/* brigade-form.css */
.brigade-form {
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 1.2rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #555;
  }
  
  .brigade-input,
  .brigade-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .brigade-input:focus,
  .brigade-textarea:focus {
    border-color: #0057A6; /* Blue from logo */
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 87, 166, 0.1);
  }
  
  .form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .save-button {
    background-color: #00a651; /* Green from logo */
    color: white;
    flex: 1;
  }
  
  .save-button:hover {
    background-color: #008f45;
  }
  
  .cancel-button {
    background-color: #777;
    color: white;
    flex: 1;
  }
  
  .cancel-button:hover {
    background-color: #666;
  }
  
  @media (max-width: 480px) {
    .form-actions {
      flex-direction: column;
    }
    
    .brigade-button {
      width: 100%;
    }
  }